.about {
    padding: 15px;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .info-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .personal-info,
  .education,
  .skills,
  .experiences {
    width: 300px;
    margin: 20px;
  }
  
  .cv-button {
  background-color: #000; /* Black background */
  color: #fff; /* White text color */
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none; /* Remove underline for anchor inside the button */
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
  }

  .cv-button:hover {
    animation: rainbowEffect 2s infinite; /* Apply the rainbow animation on hover */
  }

  .cv-button a {
    color: #fff; /* Set text color for the anchor */
    text-decoration: none; /* Remove underline for the anchor */
  }

  @keyframes rainbowEffect {
    0% {
      box-shadow: 0 0 10px 5px rgb(255, 0, 0); /* Red shadow */
    }
    16.666% {
      box-shadow: 0 0 10px 5px rgb(255, 166, 0); /* Orange shadow */
    }
    33.333% {
      box-shadow: 0 0 10px 5px rgb(255, 255, 0); /* Yellow shadow */
    }
    50% {
      box-shadow: 0 0 10px 5px rgba(0, 255, 0, 0.5); /* Green shadow */
    }
    66.666% {
      box-shadow: 0 0 10px 5px rgb(0, 0, 255); /* Blue shadow */
    }
    83.333% {
      box-shadow: 0 0 10px 5px rgb(76, 0, 130); /* Indigo shadow */
    }
    100% {
      box-shadow: 0 0 10px 5px rgb(255, 180, 228); /* Violet shadow */
    }
  }
  
  .skill-category {
    margin-top: 10px;
  }
  
  .skill-category p {
    font-weight: bold;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    margin-top: 5px;
  }
  
  .experience-item {
    margin-top: 10px;
  }
  
  .contact-info {
    margin-top: 20px;
    text-align: center;
  }

  .contact-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }
  
  .contact-button {
    background-color: #333; /* Black background */
    color: #fff; /* White text color */
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  
  .contact-button:hover {
    animation: rainbowEffect 2s infinite; /* Darker background on hover */
  }

  body {
    overflow-x: hidden;
  }

  .container {
    overflow-x: hidden;
  }

  a {
    text-decoration: none; /* Remove underline from all links */
    color: inherit; /* Inherit the color from the parent element */
  }

/* Media Query for Mobile Devices */
@media only screen and (max-width: 600px) {
  .about {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .personal-info,
  .education,
  .skills,
  .experiences {
    width: 100%; /* Make these sections full-width on smaller screens */
    margin: 10px; /* Remove margin for better use of screen width */
  }

  /* Adjust font size for better readability on smaller screens */
  .personal-info h2,
  .education h2,
  .skills h2,
  .experiences h2 {
    font-size: 1.2rem;
  }

  /* Adjust font size for better readability on smaller screens */
  .personal-info p,
  .education p,
  .skills p,
  .experiences p,
  .skill-category p,
  ul li,
  .experience-item h3,
  .experience-item p {
    font-size: 0.9rem;
  }
}

/* Media Query for Medium-Sized Screens (e.g., half-width laptop screens) */
@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .personal-info,
  .education,
  .skills,
  .experiences {
    width: 100%; /* Make these sections half-width on medium-sized screens */
    margin: 10px; /* Adjust margin for better use of screen width */
  }

  /* Adjust font size for better readability on medium-sized screens */
  .personal-info h2,
  .education h2,
  .skills h2,
  .experiences h2 {
    font-size: 1.5rem; /* Adjust font size accordingly */
  }

  /* Adjust font size for better readability on medium-sized screens */
  .personal-info p,
  .education p,
  .skills p,
  .experiences p,
  .skill-category p,
  ul li,
  .experience-item h3,
  .experience-item p {
    font-size: 1rem; /* Adjust font size accordingly */
  }
}

