.home {
    padding: 15px;
    text-align: center;
}

.portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.portfolio-item {
    margin: 20px;
    max-width: calc(50% - 20px);
}

.image-text-container {
    text-align: center;
    font-size: 24px;
}

img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 8px #000000;
}

p {
    margin-top: 10px;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 600px) {
    .home {
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .portfolio-title {
        font-size: 24px; /* Smaller font size for mobile devices */
      }

    .portfolio-item {
        max-width: 100%; /* Make portfolio items full-width on smaller screens */
    }

    .image-text-container {
        font-size: 18px; /* Reduce font size for smaller screens */
    }
}

/* Media Query for Medium-Sized Screens (e.g., half-width laptop screens) */
@media only screen and (min-width: 601px) and (max-width: 1200px) {
    .home {
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .portfolio-item {
        max-width: 100%; /* Make portfolio items full-width on smaller screens */
    }

    .image-text-container {
        font-size: 18px; /* Reduce font size for smaller screens */
    }
  }